<!-- eslint-disable -->
<style lang="scss">
.v-text-field.v-text-field--solo .v-input__control {
    min-height: 25px;
}
.c-login{
    display: flex!important;
    align-items: center;
    justify-content: center;
    &__row{
      max-width: 100%;
    }
    &__elevation{
      --border-login: #bbbbbba8;
      .v-toolbar__content{
        justify-content: center;
        font-weight: 300;
      }
      .v-card__text,
      .v-card__actions{
        padding-left: 1.25rem!important;
        padding-right: 1.25rem!important;
        border-left: 2px solid var(--border-login);
        border-right: 2px solid var(--border-login);
        text-transform: uppercase;
      }
      .v-card__actions{
        padding-bottom: 1.5rem!important;
        border-bottom: 2px solid var(--border-login);
      }
      .v-btn{
        min-width: 6rem!important;
      }
    }
  .v-toolbar__title{
    text-transform: uppercase;
  }
}
.v-text-field{
  &__slot{
    input{
      &:-webkit-autofill{
        & ~ label{
          transform: translateY(-18px) scale(0.75);
        }
      }
    }
  }
  .v-label{
    padding: 0!important;
  }
}
#app{
  .selectidioma{
    color: #fff;
  }
  .c-login{
    .v-card__text{
      padding: 0rem 2rem !important;
    }
    .v-input{
      &__control{
        border: 1px solid #ADADAD;
        border-radius: 10px;
      }
      &__slot{
        margin-bottom: initial;
        &:before,
        &:after{
          content: none!important;
        }
        input{
          padding: 10px!important;
        }
        label{
          left: 1rem!important;
        }
      }
    }
    .c-icon{
      top: 50%;
    }
    .v-input{
      margin: 1.5rem auto!important;
    }
    .v-text-field {
      padding-top: initial;
      margin-top: initial;
    }
    .v-btn{
      &.c-btn-login{
        width: 100%!important;
        border-radius: 10px;
        margin: auto;
        margin-top: initial;
        text-transform: uppercase!important;
        &.bg-black{
          background-color: #111!important;
          color:#fff!important;
        }
      }
    }
  }
}
</style>
<style lang="css" scoped>
  /* Enter and leave animations can use different */
    /* durations and timing functions.              */
    .slide-fade-enter-active {
      transition: all .3s ease;
    }
    .slide-fade-leave-active {
      transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
      transform: translateX(10px);
      opacity: 0;
    }

    .msg-error{
      position: absolute;
      bottom: 3%;
    }
</style>
<style lang="scss">
.loading{
  background-color: #fff;
  height:100%;
  width: 100%;
  position:fixed;
  z-index: 999;
  top:0;
  bottom:0;
  right:0;
  left:0;
  display: flex;
  align-items: center;
  justify-content: center;
  &__text{
    font-size: 12px;
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #000;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>
<template>
<div>
  <div class="loading" v-if="close_loading">
    <div class="loading__container">
      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      <div class="loading__text">{{$t('menu.salir')}}</div>
    </div>
  </div>
  <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <Header />
      <v-card class="c-login">
          <v-row
            align="center"
            justify="center"
            class="c-login__row"
          >
            <v-col
              cols="12"
            >
              <div class="o-login-header">
                <Logo />
              </div>
              <div class="o-login__header">
                  <h2 class="welcome" data-translate="welcome">Welcome to</h2>
                  <h3 class="h3">TxA Lab</h3>
                  <h4 class="txt1" data-translate="txt1">Please login to continue</h4>
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="3"
            >
              <v-card class=" elevation-12">
                <!--
                <v-toolbar
                  color="primary"
                  dark
                  flat
                >
                  <v-toolbar-title>Login form</v-toolbar-title>
                </v-toolbar>
                -->
                <v-card-text>
                  <transition name="slide-fade">
                    <v-alert
                      border="left"
                      color="red lighten-2"
                      class="msg-error"
                      v-if="msgError"
                      dense
                      dark
                    >
                      {{ msgError }}
                    </v-alert>
                  </transition>
                  <v-form>
                    <div class="relative">
                    <v-text-field
                      v-model="formData.email"
                      label="Login"
                      solo
                      name="login"
                      type="text"
                    ></v-text-field>
                    </div>
                    <div class="relative">
                      <v-text-field
                        v-model="formData.password"
                        id="password"
                        solo
                        small
                        label="Password"
                        name="password"
                        :type="type"
                      ></v-text-field>
                      <v-icon class="c-icon c-magnify" @click="changeType">{{eye}}</v-icon>
                    </div>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn class="c-btn-login bg-black"
                  @click="submit()"
                  :loading="loading">Login</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</div>
</template>
<script>
import Header from '@/components/Header.vue';
import Logo from '@/components/Logo.vue';

export default {
  name: 'Login',
  components: {
    Header,
    Logo,
  },
  data: () => ({
    close_loading: true,
    loading: false,
    valid: true,
    formData: {},
    dialog: true,
    notifications: false,
    sound: true,
    widgets: false,
    eye: 'mdi-eye',
    // changeType: function,
    type: 'password',
    msgError: null,
  }),
  methods: {
    activeLabels() {
      const labels = document.querySelectorAll('.v-input');
      labels.forEach((element) => {
        const inputField = element.querySelector('input');
        const labelField = element.querySelector('label');
        setTimeout(() => {
          inputField.parentNode.insertBefore(labelField, inputField.nextSibling);
        }, 0);
      });
    },
    async submit() {
      this.loading = true;
      // this.$store.state.app.setOverlay(this.loading);
      fetch(`${process.env.VUE_APP_URL_API}login`, {
        method: 'POST',
        body: JSON.stringify(this.formData),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => res.json())
        .catch((err) => {
          // console.warn('Error:', er);
          this.msgError = err.error;
          setTimeout(() => {
            this.msgError = null;
          }, 1500);
        })
        .then((response) => {
          // console.log(response);
          this.loading = false;
          if (!response.error) {
            this.$store.commit('setToken', response.token);
            localStorage.setItem('token', response.token);
            this.$store.state.app.init();
            // this.$store.
            return;
          }
          this.msgError = response.error;
          setTimeout(() => {
            this.msgError = null;
          }, 3500);

          // console.warn('ERROR', response);
        });
    },
    clear() {

    },
    changeType() {
      let text = 'password';
      if (this.type === 'password') {
        this.type = 'text';
        this.eye = 'mdi-eye-off';
        text = 'text';
      } else {
        this.type = 'password';
        this.eye = 'mdi-eye';
        text = 'password';
      }
      return text;
    },
  },
  mounted() {
    window.location.replace(`${process.env.VUE_APP_TXA}sylablogin/login`);
    this.$nextTick(this.activeLabels);

    setTimeout(() => {
      this.close_loading = false;
    }, 10000);
  },
};
</script>
